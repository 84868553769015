<!-- 日期搜索框 -->
<template>
  <div id="myDate">
    <div class="m-datepicker">
      <!-- 省份 -->
      <div
        class="b-field-input province-box"
        @mouseenter="enter()"
        @mouseleave="leave()"
      >
        <!-- 选择框 -->
        <div class="value default-value">
          {{ province == "" ? "省" : province }}
        </div>

        <div v-show="provinceBtn" class="picker">
          <!-- 数据1(特殊城市处理) -->
          <div class="picker-a">
            <div
              class="province"
              v-for="(item, index) in specialProvinces"
              :key="index"
            >
              <div class="option" @click="bl(item)">
                {{ item.shortName }}
              </div>
            </div>
          </div>
          <!-- 数据2 -->
          <div class="picker-b">
            <div
              class="province"
              v-for="(item, index) in provinces"
              :key="index"
            >
              <div class="option" @click="al(item)">
                {{ item.shortName }}
              </div>
            </div>
          </div>
          <!-- 数据3(不限) -->
          <div class="picker-b picker-c">
            <div class="province" v-for="(item, index) in wu" :key="index">
              <div class="option" @click="cl(item)">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 市 -->
      <div v-show="yincang" class="b-field-input city">
        <div class="value default-value">
          {{ city == "" ? "市" : city }}
        </div>

        <!-- 框 -->
        <div class="picker" v-show="cityBtn">
          <div class="picker-b">
            <div
              v-for="(item, index) in Pcitys"
              :key="index"
              class="option"
              @click="ml(item)"
            >
              {{ item.shortName }}
            </div>
          </div>

          <div class="picker-b picker-c">
            <div
              v-for="(item, index) in wu"
              :key="index"
              class="option"
              @click="ql(item)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <!-- 县区 -->
      <div class="b-field-input">
        <div class="value default-value" v-show="yincang">
          {{ county == "" ? "县" : county }}
        </div>
        <div class="value default-value" v-show="!yincang">
          {{ county == "" ? "区" : county }}
        </div>
        <!-- 框 -->
        <div class="picker" v-show="countyBtn">
          <div class="picker-b">
            <div
              v-for="item in countys"
              :key="item.name"
              class="option"
              @click="dl(item)"
            >
              {{ item.shortName }}
            </div>
          </div>
          <div class="picker-b picker-c">
            <div
              v-for="(item, index) in wu"
              :key="index"
              class="option"
              @click="sl(item)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { city } from "@/api/my/my.js";
// import local from "@/api/common/local.js";
import city from "@/assets/js/city.js";
export default {
  props: ["CreativeData"],

  data() {
    return {
      provinces: [], //出去特殊城市
      specialProvinces: [], //只有特殊城市
      wu: ["不限"],
      Citys: [],
      Pcitys: [],
      countys: [],
      yincang: true,
      monthBtn: false,
      a: false,
      province: null,
      city: null,
      county: null,
      provinceId: null,
      cityId: null,
      countyId: null,
      provinceBtn: false,
      cityBtn: false,
      countyBtn: false,
    };
  },

  created() {
    this.save();
  },
  methods: {
    // 发请求传值
    async save() {
      console.log("c", city.city);
      // if (local.get("city")) {
      //   // console.log("数据");
      // let data = local.get("city");
      let data = city.city;
      for (let i = 0; i < data.length; i++) {
        // 除去北京.天津.上海.重庆.
        if (
          !(
            data[i].shortName == "北京" ||
            data[i].shortName == "天津" ||
            data[i].shortName == "上海" ||
            data[i].shortName == "重庆"
          )
        ) {
          this.provinces.push(data[i]);
        }
        // 数组只有北京.天津.上海.重庆.
        if (
          data[i].id == 110000 ||
          data[i].id == 120000 ||
          data[i].id == 310000 ||
          data[i].id == 500000
        ) {
          this.specialProvinces.push(data[i]);
        }
      }
      // } else {
      //   // console.log("iii");
      //   const { code, data } = await city();
      //   if (code == 0) {
      //     local.set("city", data); //存储数据
      //     for (let i = 0; i < data.length; i++) {
      //       // 除去北京.天津.上海.重庆.
      //       if (
      //         !(
      //           data[i].shortName == "北京" ||
      //           data[i].shortName == "天津" ||
      //           data[i].shortName == "上海" ||
      //           data[i].shortName == "重庆"
      //         )
      //       ) {
      //         this.provinces.push(data[i]);
      //       }
      //       // 数组只有北京.天津.上海.重庆.
      //       if (
      //         data[i].id == 110000 ||
      //         data[i].id == 120000 ||
      //         data[i].id == 310000 ||
      //         data[i].id == 500000
      //       ) {
      //         this.specialProvinces.push(data[i]);
      //       }
      //     }
      //   }
      // }

      // const { code, data } = await city();
      // if (code == 0) {
      //   for (let i = 0; i < data.length; i++) {
      //     // 除去北京.天津.上海.重庆.
      //     if (
      //       !(
      //         data[i].shortName == "北京" ||
      //         data[i].shortName == "天津" ||
      //         data[i].shortName == "上海" ||
      //         data[i].shortName == "重庆"
      //       )
      //     ) {
      //       this.provinces.push(data[i]);
      //     }
      //     // 数组只有北京.天津.上海.重庆.
      //     if (
      //       data[i].id == 110000 ||
      //       data[i].id == 120000 ||
      //       data[i].id == 310000 ||
      //       data[i].id == 500000
      //     ) {
      //       this.specialProvinces.push(data[i]);
      //     }
      //   }
      // }
      console.log("数据", this.data1); //父组件传来的值
      // if (this.data1.workAddrProvinceId == null) {
      //   this.province = "不限";
      //   this.city = "不限";
      //   this.zz = false;
      // } else {
      //   this.zz = true;
      // }
    },

    //省份(1)
    async al(item) {
      this.city = null; //市
      this.county = null; //县
      this.countys = [];
      this.yincang = true; //显示 -- 市 选择框
      this.provinceBtn = false; //隐藏省框
      this.cityBtn = true; //显示市框
      this.province = item.shortName; //赋值省 - id
      this.provinceId = item.id;
      //---------------获取对应城市---------
      for (let i = 0; i < this.provinces.length; i++) {
        if (this.provinces[i].shortName == item.shortName) {
          this.Citys.push(this.provinces[i].children);
          for (let j = 0; j < this.Citys.length; j++) {
            for (let z = 0; z < this.Citys[j].length; z++) {
              this.Pcitys.push(this.Citys[j][z]);
            }
          }
        }
      }
    },
    // 特殊省市处理(2)
    bl(item) {
      this.city = null;
      this.county = null;
      this.countys = [];
      this.countyBtn = true;
      this.yincang = false;
      this.province = item.shortName;
      this.provinceId = item.id;
      this.cityId = item.children[0].id;
      this.city = item.children[0].shortName;
      for (let i = 0; i < this.specialProvinces.length; i++) {
        if (this.specialProvinces[i].shortName == item.shortName) {
          let arr = this.specialProvinces[i].children[0].children;
          for (let a = 0; a < arr.length; a++) {
            this.countys.push(arr[a]);
          }
        }
      }
    },
    //不限处理(3)
    cl(item) {
      this.countys = [];
      this.province = item;
      this.provinceId = item; //省id
      this.yincang = false;
      this.county = item;
      this.city = item;
      this.provinceBtn = false;

      let data = {
        province: null,
        provinceId: null,
        city: null,
        cityId: null,
        county: null,
        countyId: null,
      };
      this.$emit("workDatac", data);
    },

    //市(1)
    ml(item) {
      this.cityBtn = false;
      this.countyBtn = true;
      this.Citys = [];
      this.Pcitys = [];
      this.city = item.shortName;
      this.countys = item.children;
      this.cityId = item.id;
    },
    //市不限(2)
    ql(item) {
      this.cityBtn = false;
      this.Citys = [];
      this.Pcitys = [];
      this.city = item;
      this.county = item;

      let data = {
        province: this.province,
        provinceId: this.provinceId,
        city: null,
        cityId: null,
        county: null,
        countyId: null,
      };
      this.$emit("workData", data);
    },
    //县(0)
    dl(item) {
      this.countys = [];
      this.Citys = [];
      this.Pcitys = [];
      this.county = item.shortName;
      this.countyBtn = false;
      this.countyId = item.id;
      let data = {
        province: this.province,
        provinceId: this.provinceId,
        city: this.city,
        cityId: this.cityId,
        county: this.county,
        countyId: this.countyId,
      };
      this.$emit("workDatad", data);
    },
    //不限(1) 县
    sl(item) {
      this.countyBtn = false;
      this.county = item;
      let data = {
        province: this.province,
        provinceId: this.provinceId,
        city: this.city,
        cityId: this.cityId,
        county: null,
        countyId: null,
      };
      this.$emit("workDatab", data);
    },
    enter() {
      if (this.cityBtn || this.countyBtn) {
        this.provinceBtn = false;
      } else {
        this.provinceBtn = true;
      }
    },
    leave() {
      this.provinceBtn = false;
    },
  },
  watch: {
    CreativeData(newValue) {
      if (newValue.workAddrProvinceName == null) {
        this.province = "不限";
        this.yincang = false;
      } else {
        this.province = newValue.workAddrProvinceName;
      }
      if (
        newValue.workAddrCityName == null ||
        newValue.workAddrCityName == "北京" ||
        newValue.workAddrProvinceName == "天津" ||
        newValue.workAddrProvinceName == "上海" ||
        newValue.workAddrProvinceName == "重庆"
      ) {
        this.city = "不限";
        this.county = newValue.workAddrDistrictName;
      } else {
        this.city = newValue.workAddrCityName;
      }
      if (newValue.workAddrDistrictName == null) {
        this.county = "不限";
      } else {
        this.county = newValue.workAddrDistrictName;
      }
      // 数组只有北京.天津.上海.重庆.
      if (
        newValue.workAddrProvinceName == "北京" ||
        newValue.workAddrProvinceName == "天津" ||
        newValue.workAddrProvinceName == "上海" ||
        newValue.workAddrProvinceName == "重庆"
      ) {
        this.yincang = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.m-datepicker {
  position: relative;
  display: flex;
}
.m-datepicker .value {
  display: inline-block;
  margin-left: 10px;
  min-width: 90px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  padding: 0 14px;
  background-color: #f4f4f4;
  color: #333333;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 14px;
}

.m-datepicker .picker {
  //   display: flex;
  width: 294px;
  //   flex-wrap: wrap;
  font-size: 16px;
  min-height: 222px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 20;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.3);
  .option {
    height: 30px;
    display: flex;
    align-items: center;
  }
  padding: 10px;
  .option:hover {
    background-color: #d9d9d9;
  }
  .picker-a {
    height: 40px;
    border-bottom: 1px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
  }
  .picker-b {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    cursor: pointer;
    .province {
      margin: 2px;
    }
  }
  .picker-c {
    border-top: 1px dashed #ccc;
  }
}

.province-box {
  margin-left: 0px;
  .value {
    margin-left: 0px;
    text-align: center;
  }
}
.city {
  position: relative;
  .ci-s {
    display: flex;
    width: 294px;
    min-height: 222px;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 20;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.3);
    .picker {
      display: flex;
      width: 300px;
      align-items: center;
      flex-wrap: wrap;

      .option {
        margin-right: 10px;
      }
    }
  }
  .city-title {
    position: absolute;
    top: 44px;
    left: 10px;
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: red;
    z-index: 22;
  }
}

.county-s {
  display: flex;
  justify-content: center;

  .county-num {
    display: flex;
    width: 294px;
    flex-wrap: wrap;
    .option {
      min-width: 80px;
    }
  }
}
.option {
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
