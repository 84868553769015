<template>
  <div class="spousePreference">
    <Card :parent_id="parent_id" style="margin-bottom: 31px">
      <p class="ti">择偶条件</p>
    </Card>

    <!-- 年龄 -->
    <!-- 年龄1 -->
    <div class="content">
      <p>年龄</p>
      <div class="a">
        <el-select v-model="ageMin" placeholder="请选择">
          <el-option v-for="item in Age" :key="item" :value="item"> </el-option>
        </el-select>
        至
        <el-select v-model="ageMax" placeholder="请选择">
          <el-option v-for="item in Age" :key="item" :value="item"> </el-option>
        </el-select>
      </div>
    </div>
    <!-- 身高 -->
    <div class="content">
      <p>身高</p>
      <div class="a">
        <el-select v-model="heightMin" placeholder="请选择">
          <el-option v-for="item in heights" :key="item" :value="item">
          </el-option>
        </el-select>
        至
        <el-select v-model="heightMax" placeholder="请选择">
          <el-option v-for="item in heights" :key="item" :value="item">
          </el-option>
        </el-select>
      </div>
      <span style="margin-left: 10px">(单位: cm)</span>
    </div>
    <!-- 收入 -->
    <div class="content">
      <p>月收入</p>
      <div class="a">
        <el-select v-model="monthIncomeMin" placeholder="请选择">
          <el-option v-for="item in incomes" :key="item" :value="item">
          </el-option>
        </el-select>
        至
        <el-select v-model="monthIncomeMax" placeholder="请选择">
          <el-option v-for="item in incomes" :key="item" :value="item">
          </el-option>
        </el-select>
      </div>
      <span style="margin-left: 10px">(单位: 元)</span>
    </div>

    <!-- 学历 -->
    <div class="content">
      <p>学历</p>
      <div class="b">
        <el-select v-model="education" placeholder="请选择">
          <el-option
            v-for="(item, index) in educations"
            :key="index"
            :value="item.label"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 婚姻状况 -->
    <div class="content">
      <p>婚姻状况</p>
      <div class="b">
        <el-select v-model="marriage" placeholder="请选择">
          <el-option
            v-for="(item, index) in marriages"
            :key="index"
            :value="item.label"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 体型 -->
    <div class="content">
      <p>体型</p>
      <div class="b">
        <el-select v-model="shape" placeholder="请选择">
          <el-option
            v-for="(item, index) in shapes"
            :key="index"
            :value="item.label"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 工作地区(000) -->
    <div class="content">
      <p>工作地区</p>
      <div class="b">
        <ZeouWork
          :CreativeData="CreativeData"
          @workData="work"
          @workDatab="worka"
          @workDatac="workc"
          @workDatad="workd"
        />
      </div>
    </div>

    <!-- 有无孩子 -->
    <div class="content">
      <p>有没有孩子</p>
      <div class="b">
        <el-select v-model="existsChildren" placeholder="请选择">
          <el-option
            v-for="(item, index) in existsChildrens"
            :key="index"
            :value="item.label"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 是否想要孩子 -->
    <div class="content">
      <p>是否想要孩子</p>
      <div class="b">
        <el-select v-model="wantChildren" placeholder="请选择">
          <el-option
            v-for="(item, index) in wantChildrens"
            :key="index"
            :value="item.label"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 是否吸烟 -->
    <div class="content">
      <p>是否吸烟</p>
      <div class="b">
        <el-select v-model="doSmake" placeholder="请选择">
          <el-option
            v-for="(item, index) in doSmakes"
            :key="index"
            :value="item.label"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>是否喝酒</p>
      <div class="b">
        <el-select v-model="doDrink" placeholder="请选择">
          <el-option
            v-for="(item, index) in doDrinks"
            :key="index"
            :value="item.label"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 有无照片 -->
    <div class="content">
      <p>有无照片</p>
      <div class="b">
        <el-select v-model="avater" placeholder="请选择">
          <el-option
            v-for="(item, index) in avaters"
            :key="index"
            :value="item.label"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 按钮 -->
    <Btn style="margin-top: 56px">
      <div class="continue" @click="bcjx">保存并继续</div>
      <div class="skip" @click="tg">跳过</div>
    </Btn>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Btn from "@/components/Card/Btn.vue";
import ZeouWork from "@/components/zeouWork";
import local from "@/api/common/local.js";
import { infoselection, updateselection, infocur } from "@/api/login/login.js";
import { subTk } from "@/api/member/member.js";
export default {
  data() {
    return {
      parent_id: "", //组件
      //年龄
      ageMax: "不限",
      ageMin: "不限",
      Age: [],
      //身高
      heights: [],
      heightMax: "",
      heightMin: "",
      //收入
      incomes: [],
      monthIncomeMax: "不限",
      monthIncomeMin: "不限",
      //学历
      educations: [
        {
          value: "不限",
          label: null,
        },
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      education: "",
      //婚姻
      marriages: [
        {
          value: "不限",
          label: null,
        },
        {
          value: "未婚",
          label: "0",
        },
        {
          value: "离异",
          label: "1",
        },
        {
          value: "丧偶",
          label: "2",
        },
      ],
      marriage: "",
      //体型
      shapes: [
        {
          value: "不限",
          label: null,
        },
        {
          value: "保密",
          label: "0",
        },
        {
          value: "一般",
          label: "1",
        },
        {
          value: "瘦长",
          label: "2",
        },
        {
          value: "运动员型",
          label: "3",
        },
        {
          value: "比较胖",
          label: "4",
        },
        {
          value: "体格魁梧",
          label: "5",
        },
        {
          value: "壮实",
          label: "6",
        },
        {
          value: "苗条",
          label: "7",
        },
        {
          value: "高大美丽",
          label: "8",
        },
        {
          value: "丰满",
          label: "9",
        },
        {
          value: "富线条美",
          label: "10",
        },
      ],
      shape: "",
      shapeL: "",
      //有无孩子
      existsChildrens: [
        {
          value: "不限",
          label: null,
        },
        {
          value: "没有孩子",
          label: "0",
        },
        {
          value: "有孩子且住在一起",
          label: "1",
        },
        {
          value: "有孩子且偶尔会在一起住",
          label: "2",
        },
        {
          value: "有孩子但不在身边",
          label: "3",
        },
      ],
      existsChildren: "",

      //是否想要孩子
      wantChildrens: [
        {
          value: "不限",
          label: null,
        },
        {
          value: "视情况而定",
          label: "0",
        },
        {
          value: "想要孩子",
          label: "1",
        },
        {
          value: "不想要孩子",
          label: "2",
        },
        {
          value: "以后再告诉你",
          label: "4",
        },
      ],
      wantChildren: "",

      //是否吸烟
      doSmakes: [
        {
          value: "不限",
          label: null,
        },
        {
          value: "不要吸烟",
          label: "0",
        },
        {
          value: "可以吸烟",
          label: "1",
        },
      ],

      doSmake: "",

      //是否喝酒
      doDrinks: [
        {
          value: "不限",
          label: null,
        },
        {
          value: "不要喝酒",
          label: "0",
        },
        {
          value: "可以喝酒",
          label: "1",
        },
      ],

      doDrink: "",

      //有无照片
      avaters: [
        {
          value: "不限",
          label: null,
        },
        {
          value: "有",
          label: "1",
        },
      ],
      avater: "",
      CreativeData: "",
      SelectionArea: "",
      diqu: null,
      UserSex: "",
      mateSelection: {},
    };
  },
  components: {
    Card,
    Btn,
    ZeouWork,
  },
  created() {
    this.chsP();
    this.addnum();
    this.ci();
  },
  methods: {
    async chsP() {
      const ManShap = [
        {
          value: "不限",
          label: null,
        },
        {
          value: "保密",
          label: 0,
        },
        {
          value: "一般",
          label: 1,
        },
        {
          value: "瘦长",
          label: 2,
        },
        {
          value: "运动员型",
          label: 3,
        },
        {
          value: "比较胖",
          label: 4,
        },
        {
          value: "体格魁梧",
          label: 5,
        },
        {
          value: "壮实",
          label: 6,
        },
      ];
      const WomenShap = [
        {
          value: "不限",
          label: null,
        },
        {
          value: "保密",
          label: 0,
        },
        {
          value: "一般",
          label: 1,
        },
        {
          value: "瘦长",
          label: 2,
        },
        {
          value: "苗条",
          label: 7,
        },
        {
          value: "高大美丽",
          label: 8,
        },
        {
          value: "丰满",
          label: 9,
        },
        {
          value: "富线条美",
          label: 10,
        },
      ];
      let a = local.get("access_token");

      const { code, data } = await infocur(a);
      if (code == 0) {
        this.UserSex = data.user.sex;
        this.parent_id = data.user.id;
        console.log("性别142", this.UserSex);
        if (Number(this.UserSex) == 0) {
          // this.shapes = this.shapes.slice(0,this.shapes.length - 5);
          this.shapes = WomenShap;
          console.log("数据213", this.shapes);
        } else if (Number(this.UserSex) == 1) {
          //  this.shapes = this.shapes.slice(0,this.shapes.length - 5);
          // console.log('数据1241',this.shapes);
          this.shapes = ManShap;
        }
      }
    },
    ci() {
      this.Age.push("不限");
      for (let i = 18; i <= 99; i++) {
        this.Age.push(i);
      }
      this.heights.push("不限");
      for (let i = 129; i < 211; i++) this.heights.push(i);
      this.incomes.push("不限", 3000, 5000, 8000, 12000, 20000, 50000);
    },
    workd(item) {
      console.log("地区001", item);
      this.SelectionArea = item;
    },
    workc(item) {
      // debugger;
      console.log("地区002", item);
      this.SelectionArea = item;
    },
    worka(item) {
      console.log("地区003", item);
      this.SelectionArea = item;
    },
    work(item) {
      this.SelectionArea = item;
      console.log("地区004", this.SelectionArea);
    },
    // 初始化
    async addnum() {
      //获取临牌
      let a = local.get("access_token");
      const { code, data } = await infoselection(a);
      if (code == 0) {
        let cd = 1;
        if (cd) {
          console.log("00011");
        } else {
          console.log("999");
        }
        this.mateSelection = data;
        this.education = data.education;
        this.doSmake = data.doSmake;
        this.doDrink = data.doDrink;
        this.avater = data.isAvatar;

        // if (
        //   this.existsChildren == null ||
        //   this.existsChildren == "" ||
        //   this.existsChildren == undefined
        // ) {
        //   this.existsChildren == null;
        // } else {
        this.existsChildren = data.existsChildren;
        // }
        this.marriage = data.maritalStatus;

        if (!data.shape) {
          this.shape = data.shape;
        } else {
          this.shape = Number(data.shape);
        }
        this.wantChildren = data.wantChildren;
        // if(data.workAddrCityId == ""){

        // }
        this.CreativeData = {
          workAddrCityId:
            data.workAddrCityId == ""
              ? (data.workAddrCityId = null)
              : data.workAddrCityId,
          workAddrCityName:
            data.workAddrCityName == ""
              ? (data.workAddrCityName = null)
              : data.workAddrCityName,
          workAddrDistrictId:
            data.workAddrDistrictId == ""
              ? (data.workAddrDistrictId = null)
              : data.workAddrDistrictId,
          workAddrDistrictName:
            data.workAddrDistrictName == ""
              ? (data.workAddrDistrictName = null)
              : data.workAddrDistrictName,
          workAddrProvinceId:
            data.workAddrProvinceId == ""
              ? (data.workAddrProvinceId = null)
              : data.workAddrProvinceId,
          workAddrProvinceName:
            data.workAddrProvinceName == ""
              ? (data.workAddrProvinceName = null)
              : data.workAddrProvinceName,
        };
        console.log("---------", this.CreativeData);
        if (data.ageMax == null || data.ageMax == "") {
          this.ageMax = "不限";
        } else {
          this.ageMax = data.ageMax;
        }
        if (data.ageMin == null || data.ageMax == "") {
          this.ageMin = "不限";
        } else {
          this.ageMin = data.ageMin;
        }

        if (data.heightMax == null) {
          this.heightMax = "不限";
        } else {
          this.heightMax = data.heightMax;
        }
        if (data.heightMin == null) {
          this.heightMin = "不限";
        } else {
          this.heightMin = data.heightMin;
        }

        if (data.monthIncomeMax == null) {
          this.monthIncomeMax = "不限";
        } else {
          this.monthIncomeMax = data.monthIncomeMax;
        }
        if (data.monthIncomeMin == null) {
          this.monthIncomeMin = "不限";
        } else {
          this.monthIncomeMin = data.monthIncomeMin;
        }
      }
    },
    async Tkl() {
      const G = await subTk();
      if (G.code == 0) {
        local.SessionSet("sub_tk", G.data);
      }
    },
    async bcjx() {
      //刷新临时令牌
      this.Tkl();

      let ageMax, AgeMin, heightMin, heightMax, monthIncomeMax, monthIncomeMin;
      if (this.ageMax == "不限") {
        ageMax = null;
      } else {
        ageMax = this.ageMax;
      }
      if (this.ageMin == "不限") {
        AgeMin = null;
      } else {
        AgeMin = this.ageMin;
      }
      // 身高
      if (this.heightMax == "不限") {
        heightMax = "";
      } else {
        heightMax = this.heightMax;
      }
      if (this.heightMin == "不限") {
        heightMin = "";
      } else {
        heightMin = this.heightMin;
      }
      //收入
      if (this.monthIncomeMax == "不限") {
        monthIncomeMax = "";
      } else {
        monthIncomeMax = this.monthIncomeMax;
      }
      if (this.monthIncomeMin == "不限") {
        monthIncomeMin = "";
      } else {
        monthIncomeMin = this.monthIncomeMin;
      }

      //城市
      console.log("数据1111", this.SelectionArea);
      //体型
      console.log("体型1", this.shapeL);
      console.log("体型2", this.shape);
      // this.shape;
      // this.shapeL =

      // let a = local.get("access_token");
      let b = {
        // token: a,
        doSmake: this.doSmake || null,
        ageMax: ageMax || null,
        ageMin: AgeMin || null,
        buyCarStatus: this.mateSelection.buyCarStatus,
        constellatory: this.mateSelection.constellatory,
        createId: this.mateSelection.createId,
        createTime: this.mateSelection.createTime,
        houseStatus: this.mateSelection.houseStatus,
        initStatus: this.mateSelection.initStatus,
        marriedTime: this.mateSelection.marriedTime,
        memberId: this.mateSelection.memberId,
        occBigCategory: this.mateSelection.occBigCategory,
        occSmallCategory: this.mateSelection.occSmallCategory,
        phonenumber: this.mateSelection.phonenumber,
        sex: this.mateSelection.sex,
        updateId: this.mateSelection.updateId,
        updateTime: this.mateSelection.updateTime,
        heightMax: heightMax || null,
        heightMin: heightMin || null,
        monthIncomeMax: monthIncomeMax || null,
        monthIncomeMin: monthIncomeMin || null,
        doDrink: this.doDrink || null,
        existsChildren: this.existsChildren || null,
        education: this.education || null,
        isAvatar: this.avater || null,
        maritalStatus: this.marriage || null,
        shape: this.shape,
        wantChildren: this.wantChildren || null,
        //城市
        workAddrCityId: this.SelectionArea.cityId || null,
        workAddrCityName: this.SelectionArea.city || null,
        workAddrDistrictId: this.SelectionArea.countyId || null,
        workAddrDistrictName: this.SelectionArea.county || null,
        workAddrProvinceId: this.SelectionArea.provinceId || null,
        workAddrProvinceName: this.SelectionArea.province || null,
      };
      const { code } = await updateselection(b);
      if (code == 0) {
        this.$message({
          message: "修改成功",
          type: "success",
          offset: 100,
        });
        setTimeout(() => {
          //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          this.$router.push({
            path: `/n/user/_auth`,
          });
          //延迟时间：3秒
        }, 0);
      }
    },
    //跳过
    tg() {
      this.$router.push({
        path: `/n/user/_auth`,
      });
    },
  },
  watch: {
    ageMax(newValue, oldValue) {
      console.log("新", newValue);
      console.log("旧", oldValue);
      if (newValue <= this.ageMin) {
        this.ageMin = newValue;
      }
    },
    ageMin(newValue, oldValue) {
      console.log("新", newValue);
      console.log("旧", oldValue);
      if (newValue >= this.ageMax) {
        this.ageMax = newValue;
      }
    },
    heightMax(newValue) {
      if (newValue <= this.heightMin) {
        this.heightMin = newValue;
      }
    },
    heightMin(newValue) {
      if (newValue >= this.heightMax) {
        this.heightMax = newValue;
      }
    },
    monthIncomeMax(newValue) {
      if (newValue <= this.monthIncomeMin) {
        this.monthIncomeMin = newValue;
      }
    },
    monthIncomeMin(newValue) {
      if (newValue >= this.monthIncomeMax) {
        this.monthIncomeMax = newValue;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content:nth-child(1) {
  margin-top: 32px;
}
.content {
  width: 880px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  p {
    width: 127px;
    padding-left: 8px;
    box-sizing: border-box;
    color: #666;
  }
  .a {
    /deep/ .el-select {
      width: 124px;
      height: 40px;
    }
  }
  .b {
    /deep/ .el-select {
      width: 270px;
      height: 40px;
    }
  }
}
/deep/.el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
}
</style>